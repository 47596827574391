import { Controller } from "@hotwired/stimulus"
import { getPixelsPerMM } from "utils.js"
import { updateLocation } from "devices.js"
import interact from "interactjs";

export default class extends Controller {
  connect() {
    this.element.moveController = this;
    interact(this.element).draggable({
      max: 1,
      listeners: {
        start: this.startDrag.bind(this),
        move: this.dragMouseMove.bind(this),
        end: this.dragMouseUp.bind(this)
      }
    });
    interact(this.element).gesturable({
      listeners: {
        start: function (event) {
          window.viewport_controller.gestureStart(event);
        }.bind(this),
        move: function (event) {
          window.viewport_controller.gestureMove(event);
        }.bind(this)
      },
      ignoreFrom: ".panel"
    });
    this.element.onclick = ((event) => { this.click(event); });
    this.rotation = 0;
  }

  getX = () => {
    return parseFloat(this.element.style.left) || 0;
  }

  getY = () => {
    return parseFloat(this.element.style.top) || 0;
  }

  moveBy = (dx, dy) => {
    this.moveTo(this.getX() + dx, this.getY() + dy);
  };

  moveTo = (x, y) => {
    this.element.style.left = `${parseInt(x)}mm`;
    this.element.style.top = `${parseInt(y)}mm`;
  };

  rotate = () => {
    this.rotateBy(45);
  }

  rotateBy = (degrees) => {
    this.rotateTo(this.rotation + degrees);
  }

  rotateTo = (degrees) => {
    this.rotation = degrees % 360;
    this.element.style.transform = `rotate(${this.rotation}deg)`;
  }

  dragMouseUp(event) {
    updateLocation();
  }

  dragMouseMove(event) {
    let dx = (event.clientX - this.startX)/getPixelsPerMM()/window.zoom;
    let dy = (event.clientY - this.startY)/getPixelsPerMM()/window.zoom;

    // require at least a little movement to get started
    if (this.significantMoving || Math.abs(dx) > 2 || Math.abs(dy) > 2) {
      this.significantMoving = true;
      this.moveTo(this.originalX + dx, this.originalY + dy);
    }
  }

  bringToFront(event) {
    if (window.zIndex == null)
      window.zIndex = 1000;
    window.zIndex += 1;
    this.element.style.zIndex = window.zIndex;
  }

  startDrag(event) {
    console.log("startDrag: " + event);

    this.bringToFront();

    if (event.button != 0)
      return;

    event.stopPropagation();

    this.originalX = parseFloat(this.element.style.left) || 0;
    this.originalY = parseFloat(this.element.style.top) || 0;

    this.startX = event.clientX;
    this.startY = event.clientY;

    this.significantMoving = false;
  }

  click(event) {
    if (event.button != 0)
      return;

    if (event.ctrlKey)
      this.rotateBy(5);
    else if (event.altKey)
      this.rotateBy(-5);
    else if (event.shiftKey)
      this.rotateBy(45);
    else
      return;

    updateLocation();
  }
}
