import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "category", "screensize" ];

  deviceMatchSearch(device, filter) {
    if (
      this.matchCategory(device, filter.category) &&
      this.matchSearch(device, filter.search) &&
      this.matchScreensize(device, filter.screensize) )
      return true;

    return false;
  }

  matchScreensize(device, screensize) {
    if (screensize == -1)
      return true;
    if (device.screens.map(x => x.diag).some( x => x >= screensize && x < screensize + 1))
      return true;
    return false;
  }

  matchCategory(device, category) {
    return (category == "all" || device.category == category)
  }

  matchSearch(device, search) {
    search = search.toLowerCase().trim();
    if (
      search.length == 0 ||
      (device.make + " " + device.name).toLowerCase().includes(search) ||

      (device.search && device.search.includes(search))
    ) {
      return true;
    }
    return false;
  }

  add(evt) {
    const deviceId = evt.target.dataset["deviceId"];
    window.addDevice(deviceId);
    sa_event("add-device", { "id": deviceId });
  }

  change() {
    for (var buttonElement of this.element.querySelectorAll(".add-device-button")) {
      var device = window.devices[buttonElement.dataset["deviceId"]];
      var filter = {
        search: this.inputTarget.value,
        category: this.categoryTarget.value,
        screensize: parseInt(this.screensizeTarget.value)
      }
      if (this.deviceMatchSearch(device, filter)) {
        buttonElement.style.display = "inline";
      } else {
        buttonElement.style.display = "none";
      }
    }
  }
}
