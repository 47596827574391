import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.inspectorController = this;
    this.debug = document.location.href.includes("localhost");
  }

  setDevice(deviceController) {
    var device = deviceController.device;
    this.element.closest(".panel").querySelector("h3").innerText = `${device.make} ${device.name}`.trim();
    engine.renderFile("inspector", Object.assign(device, {"debug":this.debug})).then((html) => {
      this.element.innerHTML = html;
    });
  }
}
