import { Controller } from "@hotwired/stimulus"
import { getPixelsPerMM } from "utils.js"
import { updateLocation } from "devices.js"

export default class extends Controller {
  connect() {
    this.element.deviceController = this;
    this.id = this.data.get("id");
    this.device = window.devices[this.id];
    if (this.canOpen())
      this.closedHeight = this.device.sizes.closed.h;
    this.openHeight = this.device.sizes.front.h;
    if (this.device.screens.length > 0) {
      this.device.calculatedScreenDiagonal = this.calculateScreenSize().toPrecision(4);
      this.device.calculatedScreenRatio = this.calculateScreenRatio().toPrecision(4);
      this.device.paperRatio = this.calculatePaperRatio().toPrecision(4);
    }
  }

  calculatePaperRatio() {
    var ratio = this.device.screens[0].ratio;
    return ratio.split(":")[0] / ratio.split(":")[1];
  }

  calculateScreenSize() {
    var screenWidth = this.device.sizes.front.w * this.device.screens[0].size.w / 100;
    var screenHeight = this.device.sizes.front.h * this.device.screens[0].size.h / 100;
    var screenDiagonal = Math.sqrt(screenWidth * screenWidth + screenHeight * screenHeight);
    var screenDiagonalIn = screenDiagonal / 2.54 / 10;
    return screenDiagonalIn;
  }

  calculateScreenRatio() {
    var screenWidth = this.device.sizes.front.w * this.device.screens[0].size.w / 100;
    var screenHeight = this.device.sizes.front.h * this.device.screens[0].size.h / 100;
    var screenRatio = screenWidth / screenHeight;
    return screenRatio;
  }


  removeDevice() {
    this.remove();
    updateLocation();
  }

  remove() {
    this.element.remove();
  }

  canOpen() {
    return (!!this.device.sizes.closed);
  }

  isOpen() {
    return !this.element.classList.contains("closed");
  }

  open() {
    this.element.moveController.bringToFront();
    this.element.classList.remove("closed");
    this.element.style.height = `${this.openHeight}mm`;
    this.element.moveController.moveBy(0, this.closedHeight - this.openHeight);
  }

  close(move = true) {
    this.element.moveController.bringToFront();
    this.element.classList.add("closed");
    this.element.style.height = `${this.closedHeight}mm`;
    if (move)
      this.element.moveController.moveBy(0, this.openHeight - this.closedHeight);
  }

  toggle(event) {
    event.stopPropagation();
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
    updateLocation();
  }

  consume = (event) => {
    event.stopPropagation();
  }

  getX = () => {
    return this.element.moveController.getX();
  }

  getY = () => {
    return this.element.moveController.getY();
  }

  getDefinition(short = false) {
    let id;
    if (short) {
      id = this.device.code;
    } else {
      id = this.device.id;
    }

    var def = `${id}:${parseInt(this.getX())},${parseInt(this.getY())}`;
    if (this.element.moveController.rotation != 0)
      def = def + "," + parseInt(this.element.moveController.rotation);
    if (this.canOpen() && !this.isOpen()) {
      def = "!" + def;
    }
    return def;
  }

  inspect() {
    window.inspectorController.setDevice(this);
  }

  getSize() {
    if (this.element.classList.contains("closed")) {
      return { w: this.device.sizes.closed.w, h: this.device.sizes.closed.h };
    } else {
      return { w: this.device.sizes.front.w, h: this.device.sizes.front.h };
    }
  }

}
